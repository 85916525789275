@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.sectionWrap {
  padding-top: var(--sectionPaddingLarge);
  padding-bottom: var(--sectionPaddingLarge);
}

.columnPadding {
  @include for-mobile-screens {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.link {
  &:hover,
  &:active {
    cursor: pointer;
    > div > button {
      color: var(--teal);
    }
  }
}

.card {
  background-color: var(--grey2);
  border-radius: 16px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;

  min-height: 179px;
  height: 100%;

  @include for-mobile-screens {
    min-height: 0px;
  }


}

.middleColumn {
  flex-grow: 1;
}

.titleRow {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
}

.hovered {
  color: var(--teal);
}

.media {
  aspect-ratio: 4/3;
  height: auto;
  max-height: 100%;
  width: 100%;
  border-radius: 12px;
}
